import { IEnvironment, IEnvironmentLabel, IEnvironmentName } from './environment.interface';

export const environment: IEnvironment = {
  production: true,
  environmentName: IEnvironmentName.staging,
  environmentLabel: IEnvironmentLabel.staging,
  apiUrl: 'stg.myresqsoftware.com/',
  microservices_ports: {
      printing: null
  },
  sentry_dsn: 'https://95250475707af9e8e10ea3b2f9ed2672@o4507578158612480.ingest.us.sentry.io/4507582147592192',
  sample_rate: 1,
  traces_sample_rate: 0,
  honeypot: 'https://honeypot.stg.myresqsoftware.com/honeypot',
  release: 'frontend_e2f3ea95c71733b921846ec9642474593ce0912a',
  marketplace_master: true,
  releaseVersion: '24.3.0',
};
